<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form
        :items="items"
        :layout="{ sm: 24, md: 12, lg: 8 }"
        :default-value="search"
        @fnSearch="handleFnSearch"
      />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        :allow-selection="false"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :url-params="{ marketingType: 2 }"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1000, y: gridHeight }"
      >
        <!--      <template #operation="{ record }">-->
        <!--        <operation :options="options" :record="record" />-->
        <!--      </template>-->

        <!--      <template #promoteName="{ text }">-->
        <!--        <span>{{ text.promoteName }}</span>-->
        <!--      </template>-->
        <!--      <template #appId="{ text }">-->
        <!--        <span>{{ appId[text] }}</span>-->
        <!--      </template>-->
      </grid>
    </div>
  </div>
</template>

<script>
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
// import { useRouter } from 'vue-router';
// import Operation from '@/components/operation';
// import { Switch } from 'ant-design-vue';
import { reactive, ref, toRefs } from 'vue';
import promoteApi from '@/api/promote';

export default {
  components: {
    Grid,
    // Operation,
    SearchForm,
    // ARow: Row,
    // ACol: Col,
    // ASwitch: Switch,
  },

  setup() {
    const gridRef = ref();
    // const router = useRouter();

    const state = reactive({
      search: {},
    });

    const form = reactive({});

    return {
      items: [
        { key: 'userName', label: '姓名' },
        // { key: 'promoteName', label: '部门' },
        { key: 'companyName', label: '公司' },
      ],

      columns: [
        { dataIndex: 'userName', title: '姓名', width: 120, ellipsis: true },
        { dataIndex: 'mobileNum', title: '手机号', width: 140, ellipsis: true },
        { dataIndex: 'companyName', title: '公司', width: 180, ellipsis: true },
        {
          dataIndex: 'createTime',
          title: '传播时间',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'sourcePromoteName',
          title: '传播来源',
          ellipsis: true,
        },

        // {
        //   dataIndex: 'id',
        //   fixed: 'right',
        //   title: '操作',
        //   width: 65,
        //   slots: { customRender: 'operation' },
        // },
      ],

      options: [],

      ...toRefs(state),
      gridHeight: document.body.clientHeight - 367,
      gridRef,
      // 表格数据请求链接
      url: promoteApi.communicatorsUrl,
      form,

      handleFnSearch: value => {
        state.search = value;
      },
    };
  },
};
</script>

<style scoped>
.iss-main {
  /*max-height: calc(100vh - 185px);*/
  overflow-y: auto;
}
</style>
